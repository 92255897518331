import { useRouter } from "next/router";
import React, { useContext, useState } from "react";
import Link from "next/link";
import AuthContext from "../../contexts/AuthContext";
import Container from "../Container.js";
// import LoginModal from "./LoginModal.js";
// import SignupModal from "./SignupModal.js";
// import ForgotPasswordModal from "./ForgotPasswordModal.js";
// import NewPassowrdModal from "./NewPasswordModal.js";
import MobileNavbar from "./MobileNavbar";
import { hostEventUrl, loginUrl } from "../../config/config";
import Image from "next/image";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { removeCognitoFromLocalStorage } from "../../common/Functions";
import ProfileCard from "./ProfileCard.js";
import { successToaster } from "../../helpers/utils.js";
import Loading from "../Loading.js";
import eventContext from "../../contexts/EventContext";
import SwitchApplications from "./SwitchApplications";
toast.configure();

const NavbarPro = ({
  eventDetails,
  newUserLogin,
  logoWhite,
  color,
  disableLogo = false,
  hideProfile = false,
  mobileNavbar = true,
  availableTabs = false,
  CurrSection,
  ticketData,
  eventPage = false,
  scrollToSection
}) => {

  const authContext = useContext(AuthContext);
  const [loginModalOpen, setloginModalOpen] = useState(
    newUserLogin && !authContext.signedIn ? true : false
  );
  const [signupModalOpen, setSignupModalOpen] = useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [newPasswordModal, setNewPasswordModal] = useState(false);
  const [email, setEmail] = useState("");
  const router = useRouter();

  const { eventData } = useContext(eventContext);
  const imgLogoWidth = eventData?.navbar_icon ? 'auto' : 188;

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      Auth.signOut();
      Cookies.remove("authToken");
      removeCognitoFromLocalStorage();

      let cookies = Cookies.get();
      Object.keys(cookies).map((key) => {
        if (key.includes("CognitoIdentityServiceProvider")) Cookies.remove(key);
      });
      authContext.setAuth({ attributes: {} }, false);
      await Auth.signOut();
      let message = "Logged Out Successfully";
      successToaster(message);
      router.replace("/", undefined, { shallow: false });
    } catch (err) {
      console.error(err.message);
    }
  };
  // Call scrollToSection when navbar items are clicked
  const handleNavItemClick = (sectionId) => {
    scrollToSection(sectionId);
  };

  return (
    <>
      {/* Desktop Navbar */}
      <Container
        className="app-header navbar pb-3 pt-4 "
        childClassName="px-md-0 px-lg-0"
      >


        <div className="row app-header-2 height d-flex flex-row">
        {
          eventData?.navbar_icon ? 
          <div className="col-10">
            <img
              loading="lazy"
              src={eventData.navbar_icon}
              className="d-inline-block link-text"
              alt="Custom Icon"
              width={`${imgLogoWidth}`}
              height={50}
            />
          </div> 
          : <div className="col-10 ps-0">
            <img
              loading="lazy"
              src=""
              className="d-inline-block link-text"
              
            />
          </div> 
        }



          <div className=" pro-nav-container d-flex align-items-center justify-content-end">
            {availableTabs !== false && (
              <div className="app-menu d-none d-md-flex">
                {/* <Link href="/events">
								<a className="app-menu-item mx-lg-3 mx-md-2">Events</a>
							</Link> */}
                {availableTabs.about && (
                    <a className="app-menu-item m3-lg-3 d-flex flex-column  py-2 ms-md-2 me-md-3" onClick={() => handleNavItemClick('about')}>
                      About
                      {CurrSection === "about" && (
                        <span className="active-pill"></span>
                      )}
                    </a>
                )}
                  
                  {ticketData.length > 0 || eventData.has_access_codes && 
                  <a className="app-menu-item m3-lg-3 d-flex flex-column  py-2 ms-md-2 me-md-3" onClick={() => handleNavItemClick('tickets')}>
                  {ticketData.length > 1 ? 'Tickets' : 'Ticket'}
                  {CurrSection === "tickets" && (
                        <span className="active-pill"></span>
                      )}
                  </a>
                  }
                {availableTabs.speakers && (
                    <a className="app-menu-item m3-lg-3 d-flex flex-column  py-2 ms-md-2 me-md-3" onClick={() => handleNavItemClick('speakers')}>
                      {eventData?.speaker_section_title !== "" &&
                      eventData?.speaker_section_title
                        ? eventData?.speaker_section_title
                        : "Speakers"}
                      {CurrSection === "speakers" && (
                        <span className="active-pill"></span>
                      )}
                    </a>
                )}
                {availableTabs.workshops && (
                    <a className="app-menu-item m3-lg-3 d-flex flex-column  py-2 ms-md-2 me-md-3" onClick={() => handleNavItemClick('workshop')}>
                      {eventData?.workshop_section_title !== "" &&
                      eventData?.workshop_section_title
                        ? eventData?.workshop_section_title
                        : "Workshops"}
                      {CurrSection === "workshops" && (
                        <span className="active-pill"></span>
                      )}
                    </a>
                )}
                {availableTabs.agenda && (
                    <a className="app-menu-item m3-lg-3 d-flex flex-column  py-2 ms-md-2 me-md-3" onClick={() => handleNavItemClick('agenda')}>
                      Agenda
                      {CurrSection === "agenda" && (
                        <span className="active-pill"></span>
                      )}
                    </a>
                )}
                {availableTabs.sponsors && (
                    <a className="app-menu-item m3-lg-3 d-flex flex-column  py-2 ms-md-2 me-md-3" onClick={() => handleNavItemClick('sponsors')}>
                      {eventData?.sponsor_section_title !== "" &&
                      eventData?.sponsor_section_title
                        ? eventData?.sponsor_section_title
                        : "Sponsors"}
                      {CurrSection === "sponsors" && (
                        <span className="active-pill"></span>
                      )}
                    </a>
                )}
                {availableTabs.venue && (
                    <a className="app-menu-item m3-lg-3 d-flex flex-column  py-2 ms-md-2 me-md-3" onClick={() => handleNavItemClick('venue')}>
                      Venue
                      {CurrSection === "venue" && (
                        <span className="active-pill"></span>
                      )}
                    </a>
                )}

                {/* <a href={`${hostEventUrl}/create/event`} className="text-decoration-none">
                                <p className="app-menu-item host-event mx-lg-3 px-3 mx-md-2 py-2 hover-class">
                                    Host Event
                                </p>
                            </a> */}
              </div>
            )}
            <div className="app-menu d-flex dropdown-user ms-3">
              {authContext.signedIn === null ? (
                <Loading className={"m-auto"} color="#fb5850" />
              ) : authContext.signedIn === true ? (
                <div className=" d-flex justify-content-center align-items-center ms-3">
                  {!eventPage && (
                    <div className="me-4">
                      <SwitchApplications />
                    </div>
                  )}
                  <ProfileCard
                    onLogout={(e) => handleLogout(e)}
                    hideProfile={hideProfile}
                  />
                  {/* <span><i
											className="far fa-user"
											id="dropdownMenuButton1"
											eventData-bs-toggle="dropdown"
											aria-expanded="false"
										></i></span> */}

                  {/* <ul
											className="dropdown-menu dropdown-menu-position "
											aria-labelledby="dropdownMenuButton1"
										>
											<div  className="app-dropdown-menu-arrow-wrapper bg-dark dropdown-menu-arrow-wrapper">
												<span className="app-dropdown-menu-arrow dropdown-menu-arrow"></span>
											</div>
											<Link href={'/profile'} >
											<li className="dropdown-item">Manage Profile</li>
											</Link>
											<Link href={`${hostEventUrl}`} >
											<li className="dropdown-item">Dashboard</li>
											</Link>
											<Link href={`${hostEventUrl}/events/create`} >
											<li className="dropdown-item">
												Host Event
											</li>
											</Link>
											<li
												className="dropdown-item"
												onClick={(e) => handleLogout(e)}
											>
												Logout
											</li>
										</ul> */}
                </div>
              ) : (
                <div className="d-flex header-menu-item">
                  <p
                    id="dropdownMenuButton1"
                    // eventData-bs-toggle="dropdown"
                    // aria-expanded="false"
                  ></p>
                  &nbsp;
                  <p
                    onClick={() => {
                      router.push(
                        router.asPath === "/"
                          ? loginUrl
                          : loginUrl + `/login?redirectToRoute=${router.asPath}`
                      );
                      // setloginModalOpen(true)
                    }}
                    className="link-text far fa-user"
                  ></p>
                  {/* <p onClick={() => {
                                        router.push(router.asPath === '/' ? `${loginUrl}/signup` : `${loginUrl}/signup?redirectToRoute=${router.asPath}`)
                                        // setSignupModalOpen(true)
                                    }} className="d-none d-md-inline-block  link-text">&nbsp;/&nbsp;Signup</p> */}
                  {/* <ul
											className="dropdown-menu dropdown-menu-position"
											aria-labelledby="dropdownMenuButton1"
										>
											<div className="app-dropdown-menu-arrow-wrapper dropdown-menu-arrow-wrapper">
												<span className="app-dropdown-menu-arrow dropdown-menu-arrow"></span>
											</div>
											<li
												className="dropdown-item"
												onClick={() => }
											>
												Login
											</li>
											<li
												className="dropdown-item"
												onClick={() => setSignupModalOpen(true)}
											>
												Signup
											</li>
										</ul> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>

      {/* Mobile Navbar */}
      <MobileNavbar mobileNavbar={mobileNavbar} />

      {/* <LoginModal
                newUserLogin={newUserLogin}
                modalValue={loginModalOpen}
                modalCloseFunction={setloginModalOpen}
                setSignupModalOpen={setSignupModalOpen}
                setPasswordModalOpen={setForgotPasswordModalOpen}
            />
            <SignupModal
                modalValue={signupModalOpen}
                modalCloseFunction={setSignupModalOpen}
                setloginModalOpen={setloginModalOpen}
            />

            <ForgotPasswordModal
                modalValue={forgotPasswordModalOpen}
                modalCloseFunction={setForgotPasswordModalOpen}
                setloginModalOpen={setloginModalOpen}
                setNewPasswordModal={setNewPasswordModal}
                email={email}
                setEmail={setEmail}
            />

            <NewPassowrdModal
                modalValue={newPasswordModal}
                modalCloseFunction={setNewPasswordModal}
                setloginModalOpen={setloginModalOpen}
                email={email}
            /> */}
      <style jsx>{`
        .app-header-2{
          justify-content: center;
        }
        .header-menu-item {
          color: ${color || "#000"};
          font-size: 16px;
          text-decoration: none;
        }
        .link-text {
          cursor: pointer;
          color: ${color || "#000"};
        }
        @media only screen and (max-width: 768px) {
          .link-text {
            padding-top: 10px;
          }
        }
        .app-menu .app-menu-item {
          font-family: Prompt;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          color: ${color || " #000"};
          cursor: pointer;
        }
        .active-pill {
          border: 2.5px solid #fdb14b;
          background-clip: padding-box;
          border-radius: 10px;
        }
        /* .app-menu .app-menu-item:hover{
			color:#fb5850 !important;
		} */
        .height {
          height: 50px;
        }
        .pro-nav-container {
          position: absolute;
          right: 20px;
        }
        .dropdown-menu.show {
          inset: unset !important;
        }
        .dropdown-menu > li {
          margin: 0px !important;
        }
        .app-menu .host-event {
          font-size: 16px;
          line-height: 24px;
          color: #fb5850;
          border: 2px solid #fb5850;
          text-decoration: none;
        }

        .app-menu .user {
          font-size: 20px;
        }

        .app-menu .app-menu-item:hover {
          font-weight: 400;
        }

        .nav-buttons {
          color: #fff;
          text-decoration: none;
          z-index: 99;
        }

        .nav-buttons-active {
          color: #fb5850;
        }

        .dropdown-user {
          /* margin-top: 5px; */
          display: flex !important;
          height: 100%;
          justify-content: center;
          align-items: center;
        }

        .hover-class {
          transition: 0.3s all;
        }

        .hover-class:hover {
          color: #ffffff !important;
          background: #fb5850;
        }

        .hover-class {
          transition: 0.3s all;
        }

        .hover-class-2:hover {
          color: #000000 !important;
        }
      `}</style>
    </>
  );
};

export default NavbarPro;